import { create } from "zustand";
import { fetchJson, logEvent } from "./common.js";

export default create((set) => ({
  showHelp: false,
  subtitlesVisible: false,
  showControlBar: true,
  popupState: null,
  popoverState: null,
  breakdownScrollState: 0,
  ankiStateDirty: false,

  toggleShowControlBar() {
    set(({ showControlBar }) => ({ showControlBar: !showControlBar }));
  },

  sceneChanged(sceneNumber) {
    set({ sceneNumber });
  },

  showSubtitles() {
    set({ subtitlesVisible: true });
  },

  hideSubtitles() {
    set({ subtitlesVisible: false });
  },

  hideControlBar() {
    set({ showControlBar: false });
  },

  setActiveBreakdown(breakdown) {
    set({ breakdown });
  },

  setBreakdownScrollState(breakdownScrollState) {
    set({ breakdownScrollState });
  },

  setPopupState(state) {
    set({ popupState: state });
  },

  closePopups() {
    set({ popupState: null });
  },

  seenHelp() {
    fetch("/api/help/set_seen_help", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      credentials: "include",
      body: JSON.stringify({
        seen_help: 1,
      }),
    });

    set({ showHelp: false });
  },

  setPopoverState(popoverState) {
    set({ popoverState });
  },

  setAnkiStateDirty(dirty) {
    set({ ankiStateDirty: dirty });
  },

  async showSelected(movieId, nlps, timestamp) {
    const { episodeFolder } = await fetchJson(`/api/videos/${movieId}/folder`);

    if (nlps.length === 0) {
      nlps = ["default"];
    }

    const [{ hasSeenHelp }, { lessonStart }, ...scenesFiles] =
      await Promise.all([
        fetchJson("/api/help/seen_help"),
        fetchJson(`/api/videoProgress/episode/${movieId}`),
        ...nlps.map((nlp) =>
          fetchJson(
            `/subtitles/${encodeURIComponent(
              nlp,
            )}/${episodeFolder}/scenes.json`,
          ).catch(() => null),
        ),
      ]);

    if (!scenesFiles.every((sceneFile) => sceneFile)) {
      set({ error: "Failed to load at least some scene files" });
      return;
    }

    if (
      !scenesFiles.every(
        ({ scenes }) => scenes.length === scenesFiles[0].scenes.length,
      )
    ) {
      set({
        error: `Scene counts in scene files do not match: ${scenesFiles.map(
          ({ scenes }) => scenes.length,
        )}`,
      });
    }

    if (
      !scenesFiles[0].scenes.every(({ beg }, i) =>
        scenesFiles.every(({ scenes }) => scenes[i].beg === beg),
      )
    ) {
      set({ error: "Scene times in scene files do not match" });
      return;
    }

    const nlp = nlps.join(",");
    const scenes = scenesFiles[0].scenes.map(({ beg }, i) => ({
      beg,
      sentences: scenesFiles.map(({ scenes }) => scenes[i].sentences),
    }));

    const initialSceneTimestamp = timestamp ?? lessonStart ?? 0;
    const initialSceneIndex = scenes.findIndex(
      ({ beg }) => beg >= initialSceneTimestamp,
    );

    logEvent("start-app");

    set({
      episodeData: {
        movieId,
        episodeFolder,
        scenes,
        nlp,
      },
      sceneNumber: initialSceneIndex === -1 ? 0 : initialSceneIndex,
      showHelp: !hasSeenHelp,
    });
  },
  helpRequested() {
    set({
      showHelp: true,
    });
  },
}));
