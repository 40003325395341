import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import CenterChild from "../layout/CenterChild.js";
import Loading from "../Loading.js";
import MultiSwiper from "../swiper/MultiSwiper.js";
import { timestampToMicros, isKatakana, fetchJson } from "../../common.js";

function getIndicesOfSubstringInList(list, subStr) {
  let indices = [];
  for (let i = 0; i < list.length; i++) {
    let pos = list[i].indexOf(subStr);
    while (pos !== -1) {
      indices.push([i, pos]);
      pos = list[i].indexOf(subStr, pos + subStr.length);
    }
  }
  return indices;
}

function capitalizeKatakanaRomaji(node) {
  if (node.characters) {
    node.characters.forEach((c, i, arr) => {
      if (c.pronunciation && isKatakana(c.original)) {
        // Any string that ends with ー, must be katakana to be capitalized
        if (c.original !== "ー" || (i > 0 && isKatakana(arr[i - 1].original))) {
          c.pronunciation = c.pronunciation.toUpperCase();
        }
      }
    });
  }
  return node;
}

function addInferredDataToWords(breakdown, properNouns) {
  // Sentences are *usually* separated by newlines, but sometimes we want to
  // merge a line with just a parenthetical with the next line if it doesn't
  // have one.
  const lines = breakdown.original.split("\n").filter((x) => x);
  const linesBroken = lines
    .map((line) => {
      // If the line starts with a parenthetical, we break it out, otherwise we leave `meta` empty.
      const match = line.match(/^\s*[(（](.*?)[)）]+\s*(.*?)\s*$/);
      // Note the '+' is there to deal with cases like "（山里(やまさと)）"
      return match ? { meta: match[1], line: match[2] } : { meta: "", line };
    })
    .reduce((acc, current) => {
      // If the previous line was empty and this one has no meta, we merge them.
      if (acc.length > 0 && !acc[acc.length - 1].line && !current.meta) {
        acc[acc.length - 1].line = current.line;
        return acc;
      }
      acc.push(current);
      return acc;
    }, []);

  // Build our sentences:
  const sentences = linesBroken.map(({ meta, line }) =>
    meta ? `（${meta}） ${line}` : line,
  );

  if (breakdown.words && breakdown.words.length > 0) {
    breakdown.words.map(capitalizeKatakanaRomaji).forEach((child, i, words) => {
      child.index = i;
      const occurrences = getIndicesOfSubstringInList(
        sentences,
        child.original,
      );
      const childMatches = breakdown.words.filter(
        (c) => c.original === child.original,
      );
      if (occurrences.length === 0) {
        child.sentences = [
          i > 0 && words[i - 1].sentences.length
            ? words[i - 1].sentences[0]
            : 0,
        ];
        child.sentencePos = [
          i > 0 && words[i - 1].sentencePos.length
            ? words[i - 1].sentencePos[0] + words[i - 1].original.length
            : 0,
        ];
      } else if (childMatches.length === occurrences.length) {
        const myIndex = breakdown.words
          .filter((c) => c.original === child.original)
          .indexOf(child);
        const match = occurrences[myIndex % occurrences.length];
        child.sentences = [match[0]];
        child.sentencePos = [match[1]];
      } else {
        child.sentences = occurrences.map((o) => o[0]);
        child.sentencePos = occurrences.map((o) => o[1]);
      }
      if (child.pos === "PROPN") {
        if (properNouns.has(child.original)) {
          child.hideKanjiTranslations = true;
        } else {
          properNouns.add(child.original);
        }
      }
    });
  }
  return breakdown;
}

export default function Movie() {
  const { show: showUrl, episode: episodeUrl } = useParams();
  const [episode, setEpisode] = useState(null);
  const [config, setConfig] = useState(null);
  const [fragments, setFragments] = useState(null);
  const [error, setError] = useState("");

  const fetchBreakdown = useCallback((episode) => {
    fetchJson(`/breakdown/${episode.path}/combined.json`)
      .then((config) => {
        // If subtitles is an array, we treat it as the only possible breakdown, named "default"
        if (config.constructor === Array) {
          config = { default: "default", breakdowns: { default: config } };
        }
        for (let name of Object.keys(config["breakdowns"])) {
          const properNouns = new Set();
          config["breakdowns"][name] = config["breakdowns"][name]
            .map((sub, i) => ({
              ...sub,
              start: timestampToMicros(sub.start_time),
              end: timestampToMicros(sub.end_time),
              index: i,
            }))
            .map((e) => addInferredDataToWords(e, properNouns));
        }

        setConfig(config);
      })
      .catch((e) => {
        console.error("ERROR fetching breakdown", e);
        setError("Error fetching breakdown");
      });
  }, []);

  const fetchFragments = useCallback((episode) => {
    fetchJson(`/api/list/${episode.path}`)
      .then(setFragments)
      .catch((e) => {
        console.error("ERROR fetching fragments", e);
        setError("Error fetching fragments");
      });
  }, []);

  useEffect(() => {
    const fetchEpisode = async () => {
      const episode = await fetchJson(`/api/episode/${showUrl}/${episodeUrl}`);
      setEpisode(episode);
      fetchBreakdown(episode);
      fetchFragments(episode);
    };

    fetchEpisode().catch((e) => {
      console.error("ERROR fetching episode", e);
      setError("Error fetching episode");
    });
  }, [showUrl, episodeUrl]);

  return (
    <div>
      {!!error && (
        <CenterChild $full>
          <h2>{error}</h2>
        </CenterChild>
      )}
      {config &&
        fragments &&
        fragments.constructor === Array &&
        !error &&
        fragments.length && (
          <MultiSwiper
            episode={episode}
            config={config}
            fragments={fragments}
          />
        )}
      {!config && !error && <Loading />}
    </div>
  );
}
