import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Page from "./layout/Page.js";
import Dialog from "./layout/Dialog.js";
import user from "../assets/icons/user.svg";
import { fetchJson, updateRequest } from "../common.js";

export async function loginRequest(e, setErrorMsg, navigate) {
  e.preventDefault();
  // eslint-disable-next-line quotes
  const user = document.querySelector('input[name="user"]').value;

  try {
    const request = await updateRequest("/api/login/sendtoken", {
      user,
    });

    const jsonResponse = await request.json();

    if (request.status === 202) {
      sessionStorage.setItem("user", user);
      navigate("/sent");
      return;
    }

    if (jsonResponse && jsonResponse.error) {
      setErrorMsg(jsonResponse.error);
    } else {
      setErrorMsg("Unknown error");
      console.error("Login request error:", { request, jsonResponse });
    }
  } catch (err) {
    console.error("Email not sent error:", err.message);
  }
}

export default function Login() {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(null);

  return (
    <Page>
      <Dialog>
        <h1>Log In</h1>
        <form onSubmit={(e) => loginRequest(e, setErrorMsg, navigate)}>
          <h3 className="icon">
            <img src={user} alt="user" />
          </h3>
          <h3 className="help">Enter your email to log in with a magic link</h3>
          <div className="input-row">
            <input
              name="user"
              placeholder="Your email"
              onChange={() => setErrorMsg(null)}
            />
          </div>
          {errorMsg ? <div className="error-message">{errorMsg}</div> : null}
          <div className="button-bar">
            <input className="pointer" type="submit" value="Log in" />
          </div>
          <div
            className="input-row"
            style={{
              margin: "20px",
              textTransform: "uppercase",
              color: "#979797CC",
              textAlign: "center",
            }}
          >
            or
          </div>
          <div className="button-bar" style={{ marginTop: "0px" }}>
            <input
              type="button"
              className="pointer"
              style={{ backgroundColor: "#f54242" }}
              onClick={(e) => {
                e.preventDefault();
                fetchJson("google-login", (response) => {
                  if (response.error) {
                    setErrorMsg(response.error);
                  } else {
                    window.location.href = response.url;
                  }
                });
              }}
              value="Log in with Google"
            />
          </div>
        </form>
      </Dialog>
    </Page>
  );
}
