import styled, { css } from "styled-components";

const CenterChild = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $full }) =>
    $full &&
    css`
      width: 100vw;
      height: 100vh;
    `}

  ${({ backgroundImage }) => css`
    background: url(${backgroundImage}) no-repeat center;
    background-size: 100%;
  `}
`;

export default CenterChild;
