import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Phrase from "./phrase/Phrase.js";
import Button from "../atoms/BreakdownButton.js";
import useStore from "../../store";
import Row from "../layout/Row.js";
import { isMobileOnly } from "react-device-detect";
import Dropdown from "./Dropdown.js";
import { containsParathesis } from "../../common.js";
import IconComment from "../../assets/icons/comment.svg";
import IconPenToSquare from "../../assets/icons/pen-to-square.svg";
import IconCircleXmark from "../../assets/icons/circle-xmark.svg";

const EnglishSubtitleLine = styled.span`
  fontWeight: "normal",
  fontSize: "1rem",
  whiteSpace: "pre-line",
  marginBottom: "1rem",
  flexGrow: 1,
`;

const UpperRightCornerContainer = styled.div`
  position: absolute;
  right: -0.5rem;
  top: -0.5rem; /* Note: we move the button upward to prevent it from running into the second row on iOS. */
`;

const PhraseContainer = styled.div`
  display: inline-block;
  margin-left: ${isMobileOnly ? "1rem" : "2rem"};
  margin-bottom: ${isMobileOnly ? "1rem" : "2rem"};
`;

const NegativeInset = styled("div")({
  marginLeft: `-${isMobileOnly ? 1.5 : 2.75}rem`,
});

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 3px;
`;

const MetaInfoColumn = styled.div`
  padding-right: 10px;
  border-right: ${(props) => (props.$hasMetaInfo ? "2px solid gray" : "none")};
`;

export default function DetailedSubs({
  episode,
  breakdown,
  breakdownMenuOptions,
  isSlider,
}) {
  const navigate = useNavigate();
  const returnToShowMenu = () => navigate(`/browse/${episode.show.url}`);

  const showControlBar = useStore((state) => state.showControlBar);
  const toggleShowControlBar = useStore((state) => state.toggleShowControlBar);
  const setPopupState = useStore((state) => state.setPopupState);
  const sentences = useMemo(
    () =>
      (breakdown.words && breakdown.words.length ? breakdown.words : [])
        .filter(
          (e, i, arr) =>
            arr.find((el) => el.sentences[0] === e.sentences[0]).index === i,
        )
        .map((e) =>
          breakdown.words.filter((el) => el.sentences[0] === e.sentences[0]),
        ),
    [breakdown],
  );
  const sentencesWithMetaInfo = useMemo(
    () =>
      sentences
        .map((sentence, i) => [
          sentence.length > 0 &&
            (containsParathesis(sentence[0].original) ||
              sentence[0].is_parenthetical),
          i,
        ])
        .filter((e) => e[0])
        .map((e) => e[1]),
    [breakdown],
  );

  const englishSubsLines =
    breakdown && breakdown.translations && breakdown.translations.length
      ? breakdown.translations[0].split("\n")
      : [];
  const englishSubs = englishSubsLines.map((line) => {
    // If the line starts with a parenthetical, we break it out, otherwise we leave `meta` empty.
    const match = line.match(/^\s*(\(.*\))\s*(.*?)\s*$/);
    return match ? { meta: match[1], line: match[2] } : { meta: "", line };
  });

  const enoughSpace = window.innerWidth > 600;
  const showMetaColumn =
    enoughSpace &&
    (sentencesWithMetaInfo.length > 0 ||
      englishSubs.some((e) => e.meta.length > 0));

  const menu = (
    <>
      <Dropdown
        direction="right"
        options={[
          {
            label: (
              <span>
                <img src={IconComment} width="16" height="16" /> Chat
              </span>
            ),
            action: () => setPopupState({ popup: "chat" }),
          },
          {
            label: (
              <span>
                <img src={IconPenToSquare} width="16" height="16" /> Feedback
              </span>
            ),
            action: () => setPopupState({ popup: "feedback", breakdown }),
          },
          ...(breakdownMenuOptions || []),
          {
            label: (
              <span>
                <img src={IconCircleXmark} width="16" height="16" /> Exit Video
              </span>
            ),
            action: returnToShowMenu,
          },
        ]}
      />
      {!isSlider && (
        <Button
          className="breakdown-button"
          onClick={toggleShowControlBar}
          $fitContent
        >
          {showControlBar ? "↓" : "↑"}
        </Button>
      )}
    </>
  );

  return (
    <div style={{ position: "relative" }}>
      <UpperRightCornerContainer>{menu}</UpperRightCornerContainer>
      <NegativeInset>
        <Grid>
          {englishSubs.map((eng, i) => {
            const hasMetaInfo = eng.meta.length > 0 && showMetaColumn;
            return (
              <React.Fragment key={`englishSubs${i}`}>
                <MetaInfoColumn $hasMetaInfo={hasMetaInfo}>
                  {hasMetaInfo && (
                    <PhraseContainer>
                      {/*TODO: Setting $isGray didn't work here for some reason */}
                      <span style={{ color: "rgba(140, 140, 140, 0.85)" }}>
                        {eng.meta}
                      </span>
                    </PhraseContainer>
                  )}
                </MetaInfoColumn>
                <Row>
                  <PhraseContainer
                    style={{ paddingRight: i === 0 ? "50px" : null }}
                  >
                    {/* Note: we right pad the first translation line to make
                    sure we don't run into our upper right corner button. */}
                    <EnglishSubtitleLine>{eng.line}</EnglishSubtitleLine>
                  </PhraseContainer>
                </Row>
              </React.Fragment>
            );
          })}
          {sentences.map((sentence, i) => {
            const hasMetaInfo = sentencesWithMetaInfo.includes(i);
            return (
              <React.Fragment key={`sentence${i}`}>
                <MetaInfoColumn $hasMetaInfo={showMetaColumn && hasMetaInfo}>
                  {hasMetaInfo && (
                    <PhraseContainer>
                      <Phrase breakdown={sentence[0]} />
                    </PhraseContainer>
                  )}
                </MetaInfoColumn>
                <Row $doWrap>
                  {sentence.slice(hasMetaInfo ? 1 : 0).map((child, j) => (
                    <PhraseContainer key={`phrase${j}`}>
                      <Phrase breakdown={child} />
                    </PhraseContainer>
                  ))}
                </Row>
              </React.Fragment>
            );
          })}
        </Grid>
      </NegativeInset>
    </div>
  );
}
