import React, { useMemo, useState } from "react";
import styled from "styled-components";
import Column from "../../layout/Column.js";
import Row from "../../layout/Row.js";
import AddCard from "../../../assets/icons/blue-cards-add.svg";
import Chat from "../../../assets/icons/blue-chat.svg";
import IconLink from "../../../assets/icons/blue-link.svg";
import useStore from "../../../store.js";
import MorphemeGrid from "./MorphemeGrid.js";
import { isMobile } from "react-device-detect";

const PopupContents = styled.h1`
  text-align: left;
`;

const Span = styled.h1`
  font-size: ${({ $isBig }) => (isMobile ? 1.1 : 1.625) * ($isBig ? 2 : 1)}rem;
  margin-bottom: 0.5rem;
  transition: font-size 0.5s ease-in-out;
  cursor: pointer;
`;

const Meaning = styled.p`
  font-size: 0.75rem;
  margin: 0;
  color: ${({ $primary }) => !$primary && "rgb(180, 180, 180)"};
  text-align: ${({ $center }) => $center && "center"};
`;

const AddIconLabel = styled.span`
  color: #0091ff;
  padding-left: 5px;
  font-size: 0.75rem;
`;

const AddIcon = styled(Row)`
  cursor: pointer;
`;

export default function PhrasePopover({ breakdown }) {
  const [activeCharacterIndex, setActiveCharacterIndex] = useState(-1);
  const setPopupState = useStore((state) => state.setPopupState);

  const lacksPronunciations = useMemo(
    () =>
      !breakdown ||
      !breakdown.characters ||
      breakdown.characters.every((c) => !c.pronunciation),
    [breakdown],
  );
  const showMorphemes = useMemo(
    () =>
      breakdown &&
      breakdown.morphemes &&
      breakdown.morphemes.some(
        (m) =>
          m.original.includes(m.original) &&
          !breakdown.characters.some((c) => c.original === m.original),
      ),
    [breakdown],
  );

  function addAnkiCard() {
    setPopupState({ breakdown, popup: "anki" });
  }

  function openChatPopup() {
    setPopupState({ breakdown, popup: "chat" });
  }

  const linkToJPDB = useMemo(
    () =>
      breakdown && breakdown.jmdict_id && breakdown.jmdict_text
        ? `https://jpdb.io/vocabulary/${breakdown.jmdict_id}/${breakdown.jmdict_text}`
        : null,
    [breakdown],
  );

  return (
    <PopupContents>
      <Row $justifySpaceBetween $flexStart>
        <Column>
          {lacksPronunciations && breakdown.pronunciation && (
            <Meaning $center>{breakdown.pronunciation}</Meaning>
          )}
          {!showMorphemes && (
            <Row style={{ marginBottom: "4px" }}>
              {(breakdown.characters || [breakdown]).map(
                (breakdown, wordIndex) => (
                  <Column
                    key={wordIndex}
                    style={{ marginRight: "1.1rem", cursor: "pointer" }}
                    onClick={() =>
                      setActiveCharacterIndex(
                        wordIndex === activeCharacterIndex ? -1 : wordIndex,
                      )
                    }
                  >
                    {!lacksPronunciations && (
                      <Meaning $center>
                        {breakdown.pronunciation
                          ? breakdown.pronunciation
                          : "\u00a0"}
                      </Meaning>
                    )}
                    <Span $isBig={activeCharacterIndex === wordIndex}>
                      {breakdown.original}
                    </Span>
                    {breakdown.translations &&
                      breakdown.alphabet === "kanji" &&
                      breakdown.translations.map((translation, i) => (
                        <Meaning key={i} $primary $center>
                          {translation}
                        </Meaning>
                      ))}
                    {breakdown.lone_translations &&
                      breakdown.alphabet === "kanji" &&
                      breakdown.lone_translations.map((translation, i) => (
                        <Meaning key={i} $center>
                          {translation}
                        </Meaning>
                      ))}
                  </Column>
                ),
              )}
            </Row>
          )}
          {showMorphemes && <MorphemeGrid breakdown={breakdown} />}
          {!showMorphemes &&
            breakdown.translations.map((translation, i) => (
              <Meaning key={i} $center>
                {translation}
              </Meaning>
            ))}
        </Column>
        <Column $gap="1rem">
          <AddIcon onClick={addAnkiCard} $center>
            <img src={AddCard} alt="Add card" />
            <AddIconLabel>Add</AddIconLabel>
          </AddIcon>
          <AddIcon onClick={openChatPopup} $center>
            <img src={Chat} alt="Chat" />
            <AddIconLabel>Chat</AddIconLabel>
          </AddIcon>
          {linkToJPDB && (
            <AddIcon $center onClick={() => window.open(linkToJPDB, "_blank")}>
              <img src={IconLink} alt="JPDB.io" />
              <AddIconLabel>JPDB.io</AddIconLabel>
            </AddIcon>
          )}
        </Column>
      </Row>
    </PopupContents>
  );
}
