import React, { useRef, useCallback } from "react";
import useStore from "../../../store.js";
import styled from "styled-components";

const InvisibleButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
`;

export default function PopoverButton({ children, breakdown }) {
  const popoverState = useStore((state) => state.popoverState);
  const setPopoverState = useStore((state) => state.setPopoverState);
  const buttonRef = useRef(null);

  const placePopover = useCallback(
    (breakdown) => {
      if (
        (breakdown || (popoverState && popoverState.breakdown === breakdown)) &&
        buttonRef.current
      ) {
        setPopoverState({
          breakdown: breakdown,
          phraseButton: buttonRef.current,
        });
      }
    },
    [popoverState, breakdown],
  );

  const popoverButtonClick = useCallback(() => {
    if (popoverState && popoverState.breakdown === breakdown) {
      setPopoverState(null);
    } else {
      placePopover(breakdown);
    }
  }, [popoverState, breakdown]);

  return (
    <InvisibleButton onClick={popoverButtonClick} ref={buttonRef}>
      {children}
    </InvisibleButton>
  );
}
