import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import BreakdownButton from "../atoms/BreakdownButton";
import { isDesktop } from "react-device-detect";

const DropdownWrapper = styled.span`
  position: relative;
  cursor: pointer;
`;

const ContextMenu = styled.div`
  position: absolute;
  top: ${({ $top }) => $top};
  left: ${({ $direction }) => ($direction === "left" ? "0" : "auto")};
  right: ${({ $direction }) => ($direction === "right" ? "0" : "auto")};
  display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};
  z-index: 6;
  border: 1px solid rgba(151, 151, 151, 0.8);
  background-color: rgba(32, 32, 32, 0.95);
  border-radius: 10px;
  text-wrap: nowrap;
  overflow-y: auto;
  max-height: 60vh;
`;

const ContextMenuItem = styled.div`
  padding: 0.5rem;
  cursor: ${({ $enabled }) => ($enabled ? "pointer" : "not-allowed")};
  opacity: ${({ $enabled }) => ($enabled ? 1 : 0.5)};
  &:hover {
    background-color: ${({ $enabled }) =>
      $enabled ? "rgba(64, 64, 64, 0.95)" : "inherit"};
  }
  text-align: left;

  &:not(:first-child) {
    border-top: 1px solid rgba(151, 151, 151, 0.8);
  }
`;

export default function Dropdown({
  title,
  style = {},
  options = [],
  direction = "left", // supported values: left, center, right
  stretchContextMenu = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleOptionClick = (action, enabled) => {
    if (enabled) {
      setIsOpen(false);
      action();
    }
  };

  // Note: we're fixing the height to prevent elements below it (e.g. a
  // subtitle breakdown) from shifting vertically when the dropdown is opened.
  const buttonHeight = isDesktop ? "1.5rem" : "1.75rem";

  return (
    <DropdownWrapper style={style}>
      <BreakdownButton
        $fitContent
        className="breakdown-button"
        onClick={() => setIsOpen(!isOpen)}
        ref={buttonRef}
        $noCapitalize
      >
        {!isOpen && (
          <span style={{ display: "inline-block", height: buttonHeight }}>
            {title ? (
              <>
                {title}
                {" ▼"}
              </>
            ) : (
              "☰"
            )}
          </span>
        )}
        {isOpen && (
          <span style={{ display: "inline-block", height: buttonHeight }}>
            {title ? (
              <>
                {title}
                {" ▲"}
              </>
            ) : (
              "▲"
            )}
          </span>
        )}
        <ContextMenu
          ref={dropdownRef}
          $isOpen={isOpen}
          $direction={direction}
          $top={
            buttonRef.current ? `${buttonRef.current.offsetHeight}px` : "100%"
          }
          style={{
            width:
              buttonRef.current && stretchContextMenu
                ? buttonRef.current.offsetWidth
                : "auto",
          }}
        >
          {options
            .filter((e) => e)
            .map(({ label, action, enabled = true }, i) => (
              <ContextMenuItem
                key={i}
                onClick={() => handleOptionClick(action, enabled)}
                style={stretchContextMenu ? { textWrap: "wrap" } : {}}
                $enabled={enabled}
              >
                {label}
              </ContextMenuItem>
            ))}
        </ContextMenu>
      </BreakdownButton>
    </DropdownWrapper>
  );
}
