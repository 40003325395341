import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import styled from "styled-components";
import useStore from "../../../store.js";

function clip(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

const PopoverContainer = styled.div`
  opacity: var(--overlay-panel-opacity);
  transition: opacity 0.25s ease-in-out;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  background: rgba(64, 64, 64, 0.8);
  border-radius: 10px;
  padding: 1rem 1.5rem;
  z-index: 1;
  position: absolute;
  min-width: 150px;
  top: ${({ $bottom, $height }) => `${$bottom - $height}px`};
  left: ${({ $left }) => `${$left}px`};
  margin: 0;
  max-width: 100vw;
  max-width: 100svw;
  max-height: ${({ $bottom }) => `${$bottom}px`};
  display: flex;
  min-height: 150px;
`;

const ChildrenContainer = styled.div`
  overflow-x: auto;
  overflow-y: auto;
  flex-grow: 1;
`;

const PopoverArrow = styled.div`
  position: absolute;
  bottom: calc((0.5rem + 1px) * -1);
  &::after {
    position: absolute;
    display: block;
    content: "";
    left: ${({ $offset }) => `calc(${$offset}px - 2rem)`};
    border-color: transparent;
    border-top-color: rgba(64, 64, 64, 0.8);
    border-style: solid;
    border-width: 0.5rem 0.5rem 0 0.5rem;
    bottom: 1px;
  }
`;

const Popover = ({ onClose, children }) => {
  const ref = useRef(null);
  const [dimensions, setDimensions] = useState({});
  const popupState = useStore((state) => state.popupState);
  const state = useStore((state) => state.popoverState);
  const [buttonTop, setButtonTop] = useState(0);
  const [buttonMiddle, setButtonMiddle] = useState(0);
  const breakdownScroll = useStore((state) => state.breakdownScrollState);

  useLayoutEffect(() => {
    const updateDimensions = () => {
      setDimensions({
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      });
    };

    updateDimensions();
    const observer = new ResizeObserver(updateDimensions);
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  const recalculatePosition = useCallback(() => {
    const { top, left, width } = state.phraseButton.getBoundingClientRect();
    setButtonTop(top);
    setButtonMiddle(left + width / 2);
  }, [state.phraseButton]);

  useEffect(() => {
    const handler = (e) => {
      if (
        ref.current &&
        !ref.current.contains(e.target) &&
        (!state.phraseButton || !state.phraseButton.contains(e.target)) &&
        !popupState
      ) {
        onClose();
      }
    };
    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler, true);
    };
  }, [onClose, state.phraseButton]);

  useEffect(recalculatePosition, [breakdownScroll]);

  useEffect(() => {
    window.addEventListener("resize", recalculatePosition);
    return () => {
      window.removeEventListener("resize", recalculatePosition);
    };
  }, [state.phraseButton]);

  const popoverLeft = clip(
    buttonMiddle - dimensions.width / 2,
    0,
    window.innerWidth - dimensions.width,
  );
  return (
    <PopoverContainer
      $bottom={buttonTop - 10}
      $left={popoverLeft}
      ref={ref}
      $width={dimensions.width}
      $height={dimensions.height}
    >
      <ChildrenContainer>{children}</ChildrenContainer>
      <PopoverArrow $offset={buttonMiddle - popoverLeft} />
    </PopoverContainer>
  );
};

export default Popover;
