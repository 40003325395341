import React, { useContext } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Row from "./layout/Row.js";
import user from "../assets/icons/user.svg";
import { SessionContext } from "./ContextProvider.js";
import { colors } from "./constants.js";

const Button = styled.button`
  background: transparent;
  border: none;
  padding: 2rem 2rem;
  margin: -2rem -2rem;
  outline: none;
  cursor: pointer;
  pointer-events: all;

  svg path,
  p {
    transition: 0.2s all;
  }

  &:hover,
  &:focus {
    svg path {
      fill: ${colors.primary};
    }
    p {
      color: ${colors.primary};
    }
  }
`;

export default function ProfileButton() {
  const navigate = useNavigate();
  const [session] = useContext(SessionContext);
  const sessionName = session.name;

  return (
    <Button onClick={() => navigate("/user-menu")}>
      <Row $gap="1rem" $center>
        <img src={user} alt="user" />
        <p>{sessionName}</p>
      </Row>
    </Button>
  );
}
