import React, { useState, useEffect } from "react";
import { fetchJson } from "../../common.js";
import VideosList from "./VideosList.js";

export default function ShowsList() {
  const [shows, setShows] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    fetchJson("/api/episode/")
      .then(setShows)
      .catch((e) =>
        setError("Error fetching shows: " + e.message || "Unknown error"),
      );
  }, []);

  if (error) {
    return (
      <div>
        <h1>Error Loading Shows: </h1>
        <pre style={{ color: "red" }}>{error}</pre>
      </div>
    );
  } else if (shows === null) {
    return <h1>Loading...</h1>;
  } else if (shows.length === 0) {
    return <h1>No shows</h1>;
  }

  return <VideosList tiles={shows} wrap />;
}
