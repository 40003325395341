import React from "react";
import { toast } from "react-toastify";
import { updateRequest } from "../../../common";
import CardIcon from "../../../assets/icons/cards.svg";
import { makeAnkiCardData } from "./AnkiCreator";
import AnkiForm from "./AnkiForm";

export function editAnkiCard(cardId, data, setAnkiStateDirty) {
  updateRequest(
    "/api/anki/edit_card",
    {
      card: cardId,
      data: makeAnkiCardData(data),
    },
    () => setAnkiStateDirty(true),
  );
}

export default function AnkiEditor({ card, closeCallback }) {
  const data = card.data;

  const wordSpans = data.word.spans;
  if (!wordSpans || wordSpans.length === 0) {
    toast("Error: This card does not have a word span.");
    return null;
  }
  const wordSpan = wordSpans[0];

  const sentenceSpans = data.sentence?.spans || [];
  const sentenceOriginal = sentenceSpans
    .map((line) => line.map((word) => word.original).join(""))
    .join("\n");
  const args = {
    sentenceSpans: data.sentence?.spans || [],
    videoPath: data.video_frame_src,
    episode: { id: card.episode_id },
    isMorpheme: !!data?.isMorpheme,
    original: wordSpan?.text || "",
    translations: data?.wordTranslations || [],
    rubyBreakdown: data?.rubyBreakdown || [],
    wordRubyBreakdown: data?.wordRubyBreakdown || [],
    romaji: wordSpan.romaji || "",
    morphemeOriginal: data?.morphemeOriginal || "",
    morphemeTranslation: data?.morphemeTranslation || "",
    wordOriginal: data?.wordOriginal || wordSpan.text || "",
    wordPronunciation: data?.wordPronunciation || wordSpan.romaji || "",
    wordTranslation:
      data?.wordTranslation ||
      (data?.wordTranslations && data?.wordTranslations[0]) ||
      "",
    sentenceOriginal: sentenceOriginal,
    sentenceTranslation: data?.sentenceTranslation || "",
    lineSelection: data?.lineSelection,
    sentence:
      "lineSelection" in data
        ? sentenceOriginal.split("\n")[data.lineSelection]
        : sentenceOriginal,
    cardFront: data?.cardFront || data?.eng_word,
    cardBack: data?.cardBack || "",
    sentenceElements: data.sentence?.spans || [],
    childSpan: wordSpans,
    cardCustomFields: data.custom_fields.map((f, i) => ({
      title: f.title,
      content: [f.content],
      choice: 0,
      index: i,
      option: f?.option || { text: "Add Custom Field" },
    })),
    cardFieldIndex: data.custom_fields.length,
    closeCallback,
    submitCallback: (data) => {
      data.videoFrameSrc = args.videoPath;
      editAnkiCard(card.id, data, closeCallback);
      toast(
        <>
          <img src={CardIcon} width="16" height="16" /> Anki card edited.
        </>,
      );
    },
    autoGenerateMnemonic: false,
  };

  return <AnkiForm {...args} />;
}
