import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { logEvent } from "../common.js";
import Page from "./layout/Page.js";
import BackButton from "./atoms/BackButton.js";
import { SettingsContext } from "./ContextProvider.js";
import Header from "./layout/Header.js";
import Row from "./layout/Row.js";
import LanguageDropdown from "./atoms/LanguageDropdown.js";
import { toast } from "react-toastify";

const SettingsContainer = styled.div`
  margin: auto;
  max-width: 600px;
  padding: 20px;
`;

const LanguageList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const LanguageItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const DeleteButton = styled.button`
  background-color: #ff4136;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
`;

export default function Settings() {
  const navigate = useNavigate();
  const [settings, setSettings] = useContext(SettingsContext);

  const languages = settings?.languages || ["English"];

  const headerLeftContent = (
    <Row $gap="1rem">
      <BackButton onClick={() => navigate("/user-menu")} />
    </Row>
  );
  const headerCenterContent = <h1>Settings</h1>;

  const handleAddLanguage = async (selectedLanguage) => {
    selectedLanguage = selectedLanguage.name;
    if (selectedLanguage && !languages.includes(selectedLanguage)) {
      const newLanguages = [...languages, selectedLanguage];
      setSettings({ ...settings, languages: newLanguages });

      try {
        const response = await fetch("/api/settings/update", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...settings, languages: newLanguages }),
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error("Failed to update settings");
        }

        logEvent("add-language", { language: selectedLanguage });
      } catch (error) {
        console.error("Error updating settings:", error);
        toast.error("Failed to add language. Please try again.");
        // Revert the local state change
        setSettings({ ...settings, languages });
      }
    }
  };

  const handleDeleteLanguage = async (language) => {
    const newLanguages = languages.filter((lang) => lang !== language);
    setSettings({ ...settings, languages: newLanguages });

    try {
      const response = await fetch("/api/settings/update", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...settings, languages: newLanguages }),
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to update settings");
      }

      logEvent("delete-language", { language });
      toast.success("Language deleted successfully");
    } catch (error) {
      console.error("Error updating settings:", error);
      toast.error("Failed to delete language. Please try again.");
      // Revert the local state change
      setSettings({ ...settings, languages });
    }
  };

  return (
    <Page>
      <Header $left={headerLeftContent} $center={headerCenterContent} />
      <SettingsContainer>
        <h2>Languages</h2>
        <LanguageList>
          {languages.map((language) => (
            <LanguageItem key={language}>
              {language}
              {language !== "English" && (
                <DeleteButton onClick={() => handleDeleteLanguage(language)}>
                  Delete
                </DeleteButton>
              )}
            </LanguageItem>
          ))}
        </LanguageList>
        <Row $gap="1rem">
          <LanguageDropdown onSelect={handleAddLanguage} />
        </Row>
      </SettingsContainer>
    </Page>
  );
}
