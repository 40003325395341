import React from "react";
import styled from "styled-components";
import Card from "../atoms/Card.js";
import Dropdown from "./Dropdown.js";

const PopupContainer = styled.div`
  opacity: var(--overlay-panel-opacity);
  transition: opacity 0.25s ease-in-out;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  width: 90%;
  height: 90%;
  max-width: 800px;
  max-height: 800px;
  border-radius: 3px;
`;

const PopupHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 1rem;
`;

const PopupHeader = styled.h1`
  font-size: 1.625rem;
  font-weight: 600;
  margin-bottom: 0.7rem;
  color: inherit;
  text-align: center;
  flex-grow: 1;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
`;

const CloseButton = styled.button`
  &.close-button {
    position: absolute;
    right: 0rem;
    top: 0rem;
    margin-top: -0.25rem;
    margin-right: -0.5rem;
    padding: 0rem; /* Needed on iOS */
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 2rem;
    color: #666;
    outline: none;
  }
`;

export default function Popup({
  title,
  children,
  onClose,
  contextMenuOptions = [],
  topLeft = null,
}) {
  return (
    <>
      <Background onClick={onClose} />
      <PopupContainer>
        <div
          style={{
            display: "flex",
            maxHeight: "100%",
            maxWidth: "100%",
            padding: 0,
            margin: 0,
          }}
        >
          <Card $fillBox>
            <CloseButton className="close-button" onClick={onClose}>
              &times;
            </CloseButton>
            <PopupHeaderContainer>
              {!!contextMenuOptions.length &&
                contextMenuOptions.some((e) => e) && (
                  <Dropdown options={contextMenuOptions} />
                )}
              {topLeft}
              <PopupHeader>{title}</PopupHeader>
            </PopupHeaderContainer>
            {children}
          </Card>
        </div>
      </PopupContainer>
    </>
  );
}
