import React, { useEffect } from "react";
import useStore from "../../../store.js";
import Popover from "./Popover.js";
import PhrasePopover from "./PhrasePopover.js";

export default function PopoverManager({ breakdown }) {
  const popoverState = useStore((state) => state.popoverState);
  const setPopoverState = useStore((state) => state.setPopoverState);

  useEffect(() => {
    if (breakdown) {
      setPopoverState(null);
    }
  }, [breakdown]);

  return (
    <>
      {popoverState && (
        <Popover onClose={() => setPopoverState(null)}>
          <PhrasePopover breakdown={popoverState.breakdown} />
        </Popover>
      )}
    </>
  );
}
