import React, { useEffect, useState } from "react";
import Page from "../layout/Page.js";
import Collection from "../layout/Collection.js";
import CollectionRow from "../layout/CollectionRow.js";
import ShowsList from "./ShowsList.js";
import ProfileButton from "../ProfileButton.js";
import NumberIcon from "../NumberIcon.js";
import ContinueWatching from "./ContinueWatching.js";
import Column from "../layout/Column.js";
import styled from "styled-components";
import Header from "../layout/Header.js";
import Dropdown from "../breakdown/Dropdown.js";
import { fetchJson } from "../../common.js";
import { useNavigate } from "react-router-dom";

const MainContent = styled.main`
  margin-top: 2rem;
  margin-bottom: 4rem;
`;

export default function Home() {
  const [nrOfCards, setNrOfCards] = useState();
  const [nrOfChats, setNrOfChats] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    fetchJson("/api/anki/user_cards_nr")
      .then((result) => setNrOfCards(result.nrOfCards))
      .catch((e) => console.error("ERROR updateNrOfCards", e));
  }, []);

  useEffect(() => {
    fetchJson("/api/chat/nr_threads")
      .then((result) => setNrOfChats(result.count))
      .catch((e) => console.error("ERROR updateNrOfChats", e));
  }, []);

  return (
    <Page>
      <Column>
        <Header
          $left={<ProfileButton />}
          $center={<h1>Home</h1>}
          $right={
            <Dropdown
              direction="right"
              options={[
                {
                  label: (
                    <NumberIcon
                      number={nrOfCards}
                      icon={"deck"}
                      label={"Anki Deck"}
                    />
                  ),
                  action: () => navigate("/anki-card-view"),
                },
                {
                  label: (
                    <NumberIcon
                      number={nrOfChats}
                      icon={"chat"}
                      label={"Chats"}
                    />
                  ),
                  action: () => navigate("/chat"),
                },
              ]}
            />
          }
        />
        <MainContent>
          <ContinueWatching />
          <Collection>
            <h2>All Shows</h2>
            <CollectionRow>
              <ShowsList />
            </CollectionRow>
          </Collection>
        </MainContent>
      </Column>
    </Page>
  );
}
